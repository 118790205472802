const getEditorContent = ref => {
  return ref.current?.editor.getValue()
}

const setEditorContent = (ref, value) => {
  ref.current?.editor.setValue(value)
  ref.current?.editor.clearSelection()
}

export { getEditorContent, setEditorContent }
