import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles((theme) => ({
  strong: {
    fontWeight: 600,
    fontSize: '1.1rem',
    color: 'black'
  }
}))

const Dropdown = ({ value, setValue, choices, attrID, attrLabel }) => {
  const classes = useStyles()
  
  const handleChange = event => {
    setValue(event.target.value)
  }

  const Menu = choices.map(option => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ))
  
  return (
    <TextField
      classes={{root: classes.bold}}
      id={attrID}
      select
      label={attrLabel}
      value={value}
      onChange={handleChange}
      helperText=""
      InputLabelProps={{
        classes: {
          root: classes.strong
        }
      }}
    >
      {Menu}
    </TextField>
  )
}

export default Dropdown
