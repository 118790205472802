import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const PdfViewer = ({
  isLoading,
  pdfData,
  pdfObjectSrc,
  pdfPlaceholder
}) => {
  
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {
        isLoading ? <CircularProgress />
          : pdfData ? (
            <iframe
              src={pdfObjectSrc}
              height="100%"
              width="100%"
              type="application/pdf"
              title="pdf">
            </iframe>
          ) : <Typography variant="h6">{pdfPlaceholder}</Typography>
      }
    </Box>
  )
}

export default PdfViewer
