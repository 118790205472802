import React from 'react';
import { HashRouter, Route } from 'react-router-dom';
import Home from './pages/Home';
import Installation from './pages/Installation';
import Header from './Header';
import Ltspice2svg from './pages/Ltspice2svg';
import Problem2tex from './pages/Problem2tex';
import Documentation from './pages/Documentation';
import TryLtspice2svg from './pages/TryLtspice2svg';
import TryProblem2tex from './pages/TryProblem2tex';
import Contact from './pages/Contact';
import Donate from './pages/Donate';

const App = () => {
  return (
    <div className="container-fluid">
      <HashRouter>
        <Header />
        <Route exact path="/" component={Home} />
        <Route exact path="/installation" component={Installation} />
        <Route exact path="/ltspice2svg" component={Ltspice2svg} />
        <Route exact path="/problem2tex" component={Problem2tex} />
        <Route exact path="/documentation" component={Documentation} />
        <Route exact path="/tryltspice2svg" component={TryLtspice2svg} />
        <Route exact path="/tryproblem2tex" component={TryProblem2tex} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/donate" component={Donate} />
      </HashRouter>
    </div>
  );
};

export default App;
