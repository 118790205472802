const outputTypeChoices = [
  {
    value: 'flagSolution',
    label: 'with solution'
  },
  {
    value: 'flagAnswer',
    label: 'with answer'
  },
  {
    value: 'flagSolAns',
    label: 'with solution and answer'
  },
  {
    value: 'flagQuestion',
    label: 'questions only'
  },
]

export { outputTypeChoices }
