import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useState} from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Link } from 'react-router-dom';
import './App.css';


const Header = () => {
  const [selectedEl, setSelectedEl] = useState(0);

  const clickHandler = (el) => {
    setSelectedEl(el);
  }

  return (
  <div>
  <Navbar  className='color-nav' collapseOnSelect expand="sm" variant="light" fixed="top">
  <Navbar.Brand href="/"><img src="/images/vector_logo_300x100.png" alt="logo" width="100" height="40" style={{backgroundColor: 'white'}} /></Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
      <NavDropdown title="Browse" id="collasible-nav-dropdown">
        <NavDropdown.Item active={selectedEl === 0} onClick={() => clickHandler(0)} to="/" as={Link}>Home</NavDropdown.Item>
        <NavDropdown.Item active={selectedEl === 1} onClick={() => clickHandler(1)} to="/installation" as={Link}>Installation</NavDropdown.Item>
        <NavDropdown.Item active={selectedEl === 2} onClick={() => clickHandler(2)} to="/ltspice2svg" as={Link}>LTSpice to SVG</NavDropdown.Item>
        <NavDropdown.Item active={selectedEl === 3} onClick={() => clickHandler(3)} to="/problem2tex" as={Link}>Problem to tex</NavDropdown.Item>
        <NavDropdown.Item active={selectedEl === 4} onClick={() => clickHandler(4)} to="/documentation" as={Link}>Documentation</NavDropdown.Item>
        <NavDropdown.Item active={selectedEl === 5} onClick={() => clickHandler(5)} to="/tryltspice2svg" as={Link}>Try out - LTSpice to SVG</NavDropdown.Item>
        <NavDropdown.Item active={selectedEl === 6} onClick={() => clickHandler(6)} to="/tryproblem2tex" as={Link}>Try out - Problem to tex</NavDropdown.Item>
        <NavDropdown.Item active={selectedEl === 7} onClick={() => clickHandler(7)} to="/donate" as={Link}>Donate</NavDropdown.Item>
      </NavDropdown>
    </Nav>
  </Navbar.Collapse>
  </Navbar>
</div>
  );
};

export default Header;
