import Button from '@material-ui/core/Button'

const Convert = ({ convert }) => {
  return (
    <Button variant="contained" color="primary" component="span" onClick={convert}>
      Convert
    </Button>
  )
}

export default Convert
