import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'

import { DownloadIcon } from '../../icons'

const useStyles = makeStyles((theme) => ({
  downloadSvg: {
    '&:hover': {
      textDecoration: 'none'
    }
  }
}))

const SvgViewer = ({
  isLoading,
  svgData,
  documentName
}) => {
  const classes = useStyles()
  
  const svgSrc = `data:image/svg+xml;base64,${btoa(svgData)}`

  return (
    <>
      <Box
        width="100%"
        height="5%"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        mt="5px"
      >
        {
          svgData && (
            <Button
              className={classes.downloadSvg}
              variant="contained"
              color="default"
              component={Link}
              href={svgSrc}
              download={`${documentName}.svg`}
              startIcon={<DownloadIcon />}
            >
              Download SVG
            </Button>
          )
        }
      </Box>
      <Box
        width="100%"
        height="95%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {
          isLoading ? <CircularProgress />
            : svgData ? (
              <img
                src={svgSrc}
                alt=""
                width="100%"
                height="100%"
              />
            ) : <Typography variant="h6">Press convert to view SVG</Typography>
        }
      </Box>
    </>
  )
}

export default SvgViewer
