import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import RandomInput from './RandomInput'
import Dropdown from './Dropdown'
import Drawings from './Drawings'
import theme from '../theme'
import { outputTypeChoices } from './types/options'

const useStyles = makeStyles(() => ({
  header: {
    background: theme.bg2,
    color: '#fff',
    fontWeight: 600,
    paddingLeft: '8px',
    height: '44px',
    paddingTop: '5px'
  },
  option: {
    paddingLeft: '5px',
    paddingRight: '5px',
    marginTop: '8px',
    marginBottom: '8px'
  },
  separator: {
    height: '100%',
    margin: '0',
    width: '300px',
    marginLeft: '-8px',
    borderTop: '2px solid rgba(0, 0, 0, 0.2)'
  }
}))

const Options = ({
  compile,
  randomFlag,
  setRandomFlag,
  outputType,
  setOutputType,
  drawings,
  setDrawings
}) => {
  const classes = useStyles()

  const Separator = () => <Box className={classes.separator}></Box>
  
  return (
    <>
      <Typography
        className={classes.header}
        variant="h5"
      >
        Options
      </Typography>
      <Box display="flex" flexDirection="column" px={1}>
        <Box className={classes.option} py={1}>
          <RandomInput {...{randomFlag, setRandomFlag}} />
        </Box>
        <Separator/>
        <Box className={classes.option} py={1}>
          <Dropdown
            value={outputType}
            setValue={setOutputType}
            choices={outputTypeChoices}
            attrID="select-output-type"
            attrLabel="Output"
          />
        </Box>
        <Separator/>
        <Box className={classes.option} pt={1}>
          <Drawings {...{drawings, setDrawings}} />
        </Box>
      </Box>
    </>
  )
}

export default Options
