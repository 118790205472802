import AceEditor from "react-ace"
import "ace-builds/src-noconflict/mode-latex"
import "ace-builds/src-noconflict/theme-clouds"

import { getEditorContent } from '../lib'

const Editor = ({ editorRef, resetIdleTimer }) => {
  return (
    <AceEditor
      mode="latex"
      theme="clouds"
      name="editor"
      onChange={resetIdleTimer}
      width="100%"
      height="100%"
      fontSize={14}
      showPrintMargin={false}
      showGutter={true}
      highlightActiveLine={true}
      editorProps={{ $blockScrolling: true }}
      ref={editorRef}
    />
  )
}

export default Editor
