import React, { useState, useEffect, createRef } from 'react'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import Options from './Options'
import Main from './Main'
import theme from './theme'
import { outputTypeChoices } from './Options/types/options'
import { getEditorContent, setEditorContent } from './lib'

const useStyles = makeStyles(() => ({
  container: {
    flexGrow: 1,
    display: 'flex',
    padding: 0
  },
  options: {
    width: '300px',
    flex: '0 0 300px',
    background: theme.bg3
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  gutter: {
    width: '14px',
    flexShrink: 0,
    background: 'inherit'
  }
}))

const basicStorage = {
  get: name => window.sessionStorage.getItem(name),
  set: (name, value) => window.sessionStorage.setItem(name, value)
}

const openFileStorage = () => new Promise((resolve, reject) => {
  const storageDbName = basicStorage.get('fileDbName')
  const dbName = storageDbName || `problemToTex-${Date.now()}`

  if (storageDbName === null) {
    basicStorage.set('fileDbName', dbName)
  }

  const request = window.indexedDB.open(dbName)

  request.onupgradeneeded = event => {
    const db = event.target.result
    db.createObjectStore('drawings')
  }

  request.onerror = event => {
    reject('indexedDB error opening database')
  }

  request.onsuccess = event => {
    const db = event.target.result
    resolve(db)
  }
})

const fileStorage = {
  db: null,
  operate: request => new Promise((resolve, reject) => {
    request.onerror = event => reject('fileStorage error', event)
    request.onsuccess = event => resolve(event.target.result)
  }),
  makeRequest: () => {
    const transaction = fileStorage.db.transaction(['drawings'], 'readwrite')
    return transaction.objectStore('drawings')
  },
  get: async (name) => {
    return await fileStorage.operate(fileStorage.makeRequest().get(name))
  },
  getAll: async () => {
    return await fileStorage.operate(fileStorage.makeRequest().getAll())
  },
  set: async (name, blob) => {
    return await fileStorage.operate(fileStorage.makeRequest().put(blob, name))
  },
  clear: async () => {
    return await fileStorage.operate(fileStorage.makeRequest().clear())
  }
}

// Only for primitive data types and basic objects
const usePersistentState = (name, defaultValue) => {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    const x = basicStorage.get(name)
    if (x !== null) setValue(JSON.parse(x))
  }, [name])

  useEffect(() => {
    basicStorage.set(name, JSON.stringify(value))
  }, [value, name])

  return [value, setValue]
}

const useIdleTimer = (action, idleTime) => {
  let timer;

  const resetTimer = () => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = (setTimeout(action, idleTime))
  }

  useEffect(() => {
    resetTimer()
  }, [])

  return resetTimer
}

const editorRef = createRef()

const ProblemToTex = () => {
  const classes = useStyles()

  useEffect(() => {
    // Styling outside this component
    const prevBodyStyle = document.body.style
    document.body.style.backgroundColor = theme.bg1
    document.body.style.paddingTop = '80px'

    const h2 = document.querySelector('h2')
    const prevH2Style = h2.style
    if (h2) {
      h2.style.fontWeight = 700
      h2.style.color = theme.fg2
      h2.style.borderBottom = `4px solid ${theme.fg2}`
    }

    const main = document.querySelector('.main')
    const prevMainStyle = main.style
    if (main) {
      main.style.height = 'calc(100vh - 95px)'
      main.style.display = 'flex'
      main.style.flexDirection = 'column'
    }

    // Quick hack around Material UI
    const style = document.createElement('style')
    style.innerHTML = `
a:hover {
  color: ${theme.fg1}
}
  `;
    document.head.appendChild(style)

    return () => {
      document.body.style = prevBodyStyle
      h2.style = prevH2Style
      main.style = prevMainStyle
      document.head.removeChild(style)
    }
  }, [])

  // Persistent state
  const [drawings, setDrawings] = useState([])
  const [randomFlag, setRandomFlag] = usePersistentState('randomFlag', false)
  const [outputType, setOutputType] = usePersistentState('outputType', outputTypeChoices[0].value)
  const [documentName, setDocumentName] = usePersistentState('documentName', 'untitled')

  const editorResetIdleTimer = useIdleTimer(() => {
    const editorContent = getEditorContent(editorRef)
    if (typeof editorContent !== 'string') {
      basicStorage.set('editorContent', JSON.stringify(''))
    } else {
      basicStorage.set('editorContent', JSON.stringify(editorContent))
    }
  }, 500)
  
  const [pdfData, setPdfData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [compileButtonText, setCompileButtonText] = useState('Compile')
  const [pdfPlaceholder, setPdfPlaceholder] = useState('Press compile to view PDF')
  const [showErrorLog, setShowErrorLog] = useState(false)
  const [errorProblemToTex, setErrorProblemToTex] = useState('')
  const [errorLatex, setErrorLatex] = useState('')

  // Restore selected drawings from browser storage
  useEffect(() => {
    const restoreDrawings = async () => {
      fileStorage.db = fileStorage.db || await openFileStorage()
      const storageDrawings = await fileStorage.getAll()
      setDrawings(storageDrawings)
    }

    restoreDrawings()
  }, [])

  // Restore editor content from browser storage
  useEffect(() => {
    const x = basicStorage.get('editorContent')
    if (x !== null) setEditorContent(editorRef, JSON.parse(x))
  }, [])

  useEffect(() => {
    // Save selected drawings to browser storage
    const saveDrawings = async () => {
      fileStorage.db = fileStorage.db || await openFileStorage()
      await fileStorage.clear()
      drawings.forEach(async drawing => await fileStorage.set(drawing.name, drawing))
    }
    
    saveDrawings()
  }, [drawings])

  const compile = async () => {
    setIsLoading(true)
    setShowErrorLog(false)
    setCompileButtonText('Compiling...')
    
    const formData = new FormData();
    [...drawings].forEach(x => formData.append('multiplefiles', x))
    formData.append('prbText', getEditorContent(editorRef))
    formData.append('prbName', documentName)
    formData.append('random', randomFlag)
    formData.append('outFlag', outputType)
    formData.append('submit1', 'putDatabase') // temporary

    try {
      const response = await axios.post('/uploadprb', formData, {
        headers: {
          'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8',
          'Content-Type': 'multipart/form-data'
        }
      })
      
      if (!response.data) return;
      
      setPdfData(response.data.PdfContent)
      setErrorProblemToTex(response.data.ErrorIcemaker)
      setErrorLatex(response.data.ErrorLatex)
      setIsLoading(false)
      setCompileButtonText('Recompile')
      setShowErrorLog(!response.data.PdfContent)
    } catch (e) {
      console.error(e)
      setIsLoading(false)
      setPdfPlaceholder('Error! Is the server down?')
      setCompileButtonText('Compile')
    }
  }

  return (
    <>
      <Container maxWidth={false} className={classes.container}>

        <Box className={classes.options} boxShadow={4}>
          <Options
            {...{
              compile,
              randomFlag,
              setRandomFlag,
              outputType,
              setOutputType,
              drawings,
              setDrawings
            }}
          />
        </Box>

        <Box className={classes.gutter} />

        <Box className={classes.main} boxShadow={4}>
          <Main
            {...{
              pdfData,
              documentName,
              setDocumentName,
              isLoading,
              pdfPlaceholder,
              editorRef,
              editorResetIdleTimer,
              showErrorLog,
              setShowErrorLog,
              errorProblemToTex,
              errorLatex,
              compileButtonText,
              compile
            }}
          />
        </Box>
        
      </Container>
    </>
  )
}

export default ProblemToTex
