import React,{useEffect} from 'react';

const Contact = () => {
  useEffect(()=>{
    document.title='Icewire - Contact'
  },[])
  return <div className="main">
  <h2>
    Contact</h2>
  <p>We are located in Toronto, Canada</p>
  <p>david.johns@icewire.ca</p>
</div>
};

export default Contact;
