import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

const Ltspice2svg = () => {
  useEffect(()=>{
    document.title='Icewire - ltspice2svg'
  },[])
  return <div className="main">
  <h2>ltspice2svg: Why use LTSpice to SVG?</h2>
  <p>
    For a modest sized schematic, a nicely hand drawn schematic
    takes about 1 min to create.  Unfortunately,
    using a general purpose drawing program to create the same schematic takes from 10 to 15 minutes even if a library of symbols are made in advance.</p>
  <p>
    However, a good schematic editing tool can create a schematic in about 2 minutes.
    Unfortunately, schmematic editors are well known to create schematics that are
    not suitable for publications in research papers or educational material.</p>
  <p>
    The purpose of ltspice2svg is to let the user create an LTspice schematic and
    then use ltspice2svg to "beautify" the schematic so that the resulting output can
    be used in publications.  If needed, the svg file can be further edited (or tweaked) using Inkscape.</p> 
  <h2>ltspice2svg - Examples</h2>
  <div className='rowC'>
    <div >
      <figure>
        <img src="/images/example_asc.png" alt="LTSpice example" style={{width: '50%'}} />
        <br />
        <figcaption>Example asc file: <a target="_blank" href="/examples/example.asc" download>example.asc</a></figcaption>
      </figure> 
      </div>
    <div >
      <figure>
        <img src="/images/example_svg_subscript.png" alt="SVG example" style={{width: '70%'}} />
        <br />
        <figcaption>Example svg file: <a target="_blank" href="/examples/example.svg" download>example.svg</a></figcaption>
      </figure>
    </div>
  </div>

  <div className='rowC'>
    <div >
      <figure>
        <img src="/images/example2_asc.png" alt="LTSpice example" style={{width: '50%'}} />
        <br />
        <figcaption>Example asc file: <a target="_blank" href="/examples/example2.asc" download>example2.asc</a></figcaption>
      </figure> 
      </div>
    <div >
      <figure>
        <img src="/images/example2_svg_subscript.png" alt="SVG example" style={{width: '60%'}} />
        <br />
        <figcaption>Example svg file: <a target="_blank" href="/examples/example2.svg" download>example2.svg</a></figcaption>
      </figure>
    </div>
  </div>
  <p>The above examples requires the custom "svg" library to be installed for LTSpice (see <Link to="/installation">Installation</Link>)<br /><br />
    The svg file can be imported into Inkscape (<a target="_blank" rel="noreferrer" href="https://inkscape.org/">Inkscape</a>) and, if needed, easily modified <br />
    (things should remain on grid)
  </p>
  <p>You can also create your own LTSpice symbols but they should be placed in the svg directory below where -symPath points to.  No new symbol names should be the same as existing LTSpice symbols.</p>
  <p>You can also create your own svg symbols to go with your new LTSpice symbols (if you don't like the automatically generated svg symbols).  Place any new svg symbol inside the symDefn.svg file within the svg directory (see existing examples)</p>
  <h2>Usage</h2>
  <p>For the above example (in the command line) run ...<br />
    % ltspice2svg -export=example.svg -text=subscript example.asc
  </p>
  <h3>Examples</h3>
  <p>A one figure example can be found within the zip file: <a target="_blank" href="/examples/FigureAsc.zip" download>FigureAsc.zip</a><br />
    - The pdf for this basic example is here: <a target="_blank" href="/examples/figureAsc.pdf" download>figureAsc.pdf</a></p>
  <p>A presentation example (using the Latex Beamer class) and making use of LTSpice figures can be found within the zip file: <a target="_blank" href="/examples/TalkAsc.zip" download>TalkAsc.zip</a><br />
    - The pdf for this presentation example is here: <a target="_blank" href="/examples/talkAsc.pdf" download>talkAsc.pdf</a> </p>
  <h2>More Information</h2>
  <p>For more info, see <Link to="/documentation">Documentation</Link></p>
</div>

};

export default Ltspice2svg;
