import React,{useEffect} from 'react';
import '../App.css';

const Documentation = () => {
  useEffect(()=>{
    document.title='Icewire - Documentation'
  },[])
  return <div className="main">
  
  <h2>Documentation</h2>
  
  <p><a href="/documentation/ltspice2svg.pdf" target="_blank" download>ltspice2svg.pdf</a></p>

  
  <p><a href="/documentation/problem2tex.pdf" target="_blank" download>problem2tex.pdf</a></p>
</div>

};

export default Documentation;
