import React,{useEffect} from 'react';

const Installation = () => {
  useEffect(()=>{
    document.title='Icewire - Installation'
  },[])
  return <div className="main">
  <h2>Install LTSpice</h2>
  <li>Install <a target="_blank" rel="noreferrer" href="https://www.eecg.utoronto.ca/~johns/LTSpice/LTSpice.html">LTSpice</a></li>
  <p>You can use LTSpice with its built in symbols but it is suggested you add an svg library to improve the schematic drawing experience.
  </p>
  <li>Install svg library for LTSpice: <a href="/installFiles/svg.zip" target="_blank" download>svg.zip</a></li>
  <li>Install the symbols in the LTSpice symbol directory</li>
  - For Windows ... typically in C:"user"\Documents\LTspiceXVII\lib\sym\svg (svg is the new added directory)
  <br />
  - For MAC ... typically in "user"/Library/ApplicationSupport/LTspice/lib/sym/svg (svg is the new added directory)
  <h3>Install ltspice2svg</h3>
  <li>Download the appropriate zip file for your operating system</li>
  <p>Windows(64 bit): <a target="_blank" href="/installFiles/ltspice2svgWin64_210302.zip" download>ltspice2svgWin64_210302.zip</a><br />
    MacOS (64 bit): <a target="_blank"  href="/installFiles/ltspice2svgMacOS64_210302.zip" download>ltspice2svgMacOS64_210302.zip</a><br />
    Linux (64 bit): <a target="_blank"  href="/installFiles/ltspice2svgLinux64_210302.zip" download>ltspice2svgLinux64_210302.zip</a></p>
  <li>Unzip zip file</li>
  <li>Copy ltspice2svg file to the directory where you want it installed (typical directories shown below)</li>
  <p>Windows: C:\Program Files\
    <br />
    MacOS/Linux: /usr/local/bin
  </p>
  <li>Test that it is installed correctly and the PATH has been updated: run the following command in another terminal window (MacOS/Linux) or another command prompt (Windows)</li>
  <p>ltspice2svg --help</p>

  <h3>Test Manual Setup</h3>
  <li>Download the following LTSpice file: <a target="_blank" href="/examples/ex01.asc" download>ex01.asc</a></li>
  <p>Run the following 2 commands on ex01.asc file:<br />
    ltspice2svg -export=ex01.svg ex01.asc<br />
    inkscape -D --export-filename=ex01.pdf --export-latex ex01.svg 
  </p>
  <p>Include the resulting .pdf_tex file using \input into your latex document</p>



  <h3>Integration with Latex/Inkscape</h3>
  <p>You can use ltspice2svg as a stand alone app but for Latex users, it is beneficial to have it automatically
    work within a Latex environment.  In this way, if an LTSpice figure is updated, the updated figure is automatically included 
    in the updated latex document. For this to work, Inkscape needs to be installed as it is used for Latex to include svg figures.
  </p>
  <p>First ensure that Inkscape and ltspice2svg are both in your PATH so that they can be called from the command line (this can be 
    tested by running "inkscape --help" and "ltspice2svg --help")</p>
  <li>Install <a target="_blank" rel="noreferrer" href="https://inkscape.org/">Inkscape</a></li>
  <p><b>You should be using Inkscape version 1.0 or above.</b><br />
    (versions below 1.0 have issues with exporting for latex)
  </p>
  <h3>Test Integrated Setup</h3>
  <li>Add --shell-escape flag when calling Latex (and/or pdflatex)</li>
  <p>For example, in texmaker, this flag can be added in preferences as
    <br />
    "/Library/TeX/texbin/latex" --shell-escape -interaction=nonstopmode %.tex
    <br />
    "/Library/TeX/texbin/pdflatex" -synctex=1  --shell-escape  -interaction=nonstopmode %.tex<br /><br />
    the "--shell-escape" flag is required so that Latex can call a command line app 
  </p>
  <li>Download the following example: <a target="_blank" href="/examples/FigureAsc.zip" download>FigureAsc.zip</a></li>
  <li>Unzip and open the latex example: figureAsc.tex</li>
  <li>Delete all the files in the tmp directory and rebuild the latex example</li>
  <p>In this example, one LTSpice figure is included into a latex document. The LTSpice figure is automatically converted to an svg file
    (using ltspice2svg) and then the svg file is included (using inkscape). You should be able to delete all the files in the tmp
    directory and when run again, they should be re-generated automatically.</p>
  
  <h3>Install problem2tex</h3>
  <li>Download the appropriate zip file for your operating system</li>
  <p>Windows(64 bit): <a target="_blank" href="/installFiles/problem2texWin64_220221.zip" download>problem2texWin64_220221.zip</a><br />
    MacOS (64 bit): <a target="_blank"  href="/installFiles/problem2texMacOS64_220221.zip" download>problem2texMacOS64_220221.zip</a><br />
    Linux (64 bit): <a target="_blank"  href="/installFiles/problem2texLinux64_220221.zip" download>problem2texLinux64_220221.zip</a></p>
  <li>Unzip zip file</li>
  <li>Copy problem2tex file to the directory where you want it installed (typical directories shown below)</li>
  <p>Windows: C:\Program Files\
    <br />
    MacOS/Linux: /usr/local/bin
  </p>
  <li>Test that it is installed correctly and the PATH has been updated: run the following command in another terminal window (MacOS/Linux) or another command prompt (Windows)</li>
  <p>problem2tex --help</p>
  <p>problem2tex can also be used in an integrated setup with Latex similar to ltspice2svg</p>
  
  <h3>Source Code</h3>
  <p>Source code is available at</p>
  <li><a target="_blank" rel="noreferrer" href="https://github.com/icewire314/ltspice2svg">https://github.com/icewire314/ltspice2svg</a></li>
  <li><a target="_blank" rel="noreferrer" href="https://github.com/icewire314/problem2tex">https://github.com/icewire314/problem2tex</a></li>
<p> </p>
<p>&nbsp;</p>
</div>
};

export default Installation;
