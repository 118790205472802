import Typography from '@material-ui/core/Typography'

const Header = () => {
  return (
    <Typography
      variant="h4"
    >
      LTspice to SVG
    </Typography>
  )
}

export default Header
