import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

import Header from './Header'
import Editor from './Editor'
import PdfViewer from './PdfViewer'
import ErrorLog from './ErrorLog'
import theme from '../theme'

const useStyles = makeStyles(() => ({
  header: {
    height: '44px',
    position: 'relative',
    background: theme.bg2,
  },
  body: {
    display: 'flex',
    height: '100%'
  },
  editor: {
    flex: '1 1 0',
    height: 'calc(100vh - 189px)'
  },
  viewer: {
    flex: '1 1 0',
    backgroundColor: theme.bg3
  },
  gutter: {
    width: '14px',
    flexShrink: 0,
    background: 'inherit'
  }
}))

const Main = ({
  pdfData,
  documentName,
  setDocumentName,
  isLoading,
  pdfPlaceholder,
  editorRef,
  editorResetIdleTimer,
  showErrorLog,
  setShowErrorLog,
  errorProblemToTex,
  errorLatex,
  compileButtonText,
  compile
}) => {
  const classes = useStyles()

  const pdfObjectSrc = `data:application/pdf;base64,${pdfData}`

  return (
    <>
      <Box className={classes.header}>
        <Header
          {...{
            editorRef,
            documentName,
            setDocumentName,
            pdfData,
            showErrorLog,
            setShowErrorLog,
            errorProblemToTex,
            errorLatex,
            compileButtonText,
            compile
          }} />
      </Box>
      <Box className={classes.body}>
        <Box className={classes.editor}>
          <Editor editorRef={editorRef} resetIdleTimer={editorResetIdleTimer} />
        </Box>
        <Box className={classes.gutter} />
        <Box className={classes.viewer}>
          { showErrorLog
            ? <ErrorLog
                {...{
                  errorProblemToTex,
                  errorLatex
                }} />
            : <PdfViewer
                {...{
                  isLoading,
                  pdfData,
                  pdfObjectSrc,
                  pdfPlaceholder
                }} />
          }
        </Box>
      </Box>
    </>
  )
}

export default Main
