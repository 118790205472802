const textChoices = [
  {
    value: 'noChange',
    label: 'no change'
  },
  {
    value: 'latex',
    label: 'latex'
  },
  {
    value: 'subscript',
    label: 'subscript'
  },
]

const tDotChoices = [
  {
    value: true,
    label: 'with dots'
  },
  {
    value: false,
    label: 'without dots'
  },
]

const fontChoices = [
  {
    value: 'Arial',
    label: 'Arial'
  },
  {
    value: 'Times New Roman',
    label: 'Times New Roman'
  },
  {
    value: 'Courier',
    label: 'Courier'
  },
  {
    value: 'Helvetica',
    label: 'Helvetica'
  },
]

export {textChoices, tDotChoices, fontChoices}
