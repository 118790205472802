import React,{useEffect} from 'react';
import ProblemToTex from '../ProblemToTex';

const TryProblem2tex = () => {
  useEffect(()=>{
    document.title='Icewire - Try problem2tex'
  },[])
  return (
    <div className="main">
      <h2>Problem to Tex</h2>
      <ProblemToTex/>
    </div>
  );
};

export default TryProblem2tex;
