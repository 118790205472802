import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: '10px'
  },
  textField: {
    marginBottom: '5px'
  }
}))

const Upload = ({
  uploadFile, setUploadFile
}) => {
  const classes = useStyles()
  
  const [inputVal, setInputVal] = useState('')

  const handleChangeFile = async event => {
    const file = event.target.files.item(0)

    setUploadFile(file)
    
    // Reset the value of the input element so the change event will get triggered again if the previous file is selected again
    setInputVal('')
  }
  
  return (
    <>
      <input
        accept=".asc"
        id="upload-asc"
        type="file"
        onChange={handleChangeFile}
        value={inputVal}
      />
      <label htmlFor="upload-asc">
        <Button variant="contained" color="primary" component="span" className={classes.button}>
          Upload .asc
        </Button>
      </label>
      <TextField
        className={classes.textField}
        size="small"
        value={uploadFile && uploadFile.name}
        variant="outlined"
      />
    </>
  )
}

export default Upload
