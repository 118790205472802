import React,{useEffect} from 'react';

const Donate = () => {
  useEffect(()=>{
    document.title='Icewire - Donate'
  },[])
  return <div className="main">
  <p>If you find this app useful, consider buying us a hot chocolate (We are not a coffee drinkers)...</p>
  <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
    <input type="hidden" name="cmd" defaultValue="_s-xclick" />
    <input type="hidden" name="hosted_button_id" defaultValue="CYWFMBB9VXMUY" />
    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" border={0} />
  </form>
</div>
};

export default Donate;
