import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

const Problem2tex = () => {
  useEffect(()=>{
    document.title='Icewire - problem2tex'
  },[])
  return <div className="main">
    <h2>Why the world needs "Problem to tex"</h2>
    <p>There are just not enough exercises, examples and worked out problems to learn technical material.  
      Our hope is that problem2tex can help people create technical exercises which can be
      posted online.
    </p>
  <h2>Problem to tex</h2>
  <p>A latex problem can be created with parameter inputs which
    are randomly chosen from a set of values.  The solution is automatically
    generated for the new parameters and SI-prefixes are done automatically.</p>
  <p>An example basic02.prb file is the following:</p>
  <p>
    PARAM{'{'}V_1 = [2, 3, 4, 5] # units=V{'}'}<br />
    PARAM{'{'}R_1 = [6, 7, 8, 9] # units=k \Omega{'}'}<br />
    Given VAL{'{'}V_1,={'}'} and VAL{'{'}R_1,={'}'}, find the current  $I_R = V_1/R_1$<br />
    \begin{'{'}solution{'}'}<br />
    RUN{'{'}I_R=V_1/R_1 # units=A{'}'}<br />
    \hilite{'{'}VAL{'{'}I_R,={'}'}{'}'}<br />
    \end{'{'}solution{'}'}<br />
    \begin{'{'}answer{'}'}<br />
    VAL{'{'}I_R,={'}'}<br />
    \end{'{'}answer{'}'}
  </p>
  <p>Running this .prb file through latex, the following pdf is generated:</p>
  <figure>
    <img src="/images/basic02.png" alt="solution" style={{width: '40%'}} />
    <br />
  </figure>
  <p>LTSpice figures can be included in the problems with varying parameters in LTSpice figures</p>
  <p>For more info, see <Link to="/documentation">Documentation</Link></p>
  <h3>Examples</h3>
  <p>The above basic example can be found within the zip file: <a target="_blank" href="/examples/prbBasic.zip" download>prbBasic.zip</a><br />
    - The pdf for this basic example is here: <a target="_blank" href="/examples/prbBasic.pdf" download>prbBasic.pdf</a></p>
  <p>A full problem set example can be found within the zip file: <a target="_blank" href="/examples/prbProblemSet.zip" download>prbProblemSet.zip</a><br />
    This problem set has many schematics, is fully parameterized and the solution is automatically updated as the parameters are changed.  Note that the graph for question 6 is also automatically updated by using tikzpicture within Latex.<br />
    - The pdf for this problem set is here: <a target="_blank" href="/examples/problemSet.pdf" download>problemSet.pdf</a></p>
</div>


};

export default Problem2tex;
