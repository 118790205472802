import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { textChoices, tDotChoices, fontChoices } from './types/options'

const useStyles = makeStyles((theme) => ({
  header: {
    background: '#c4e8ff'
  },
  textOption: {
    width: '8em'
  },
  tDotOption: {
    width: '8em'
  }
}))

const makeMenuItem = option => (
  <MenuItem key={option.value} value={option.value}>
    {option.label}
  </MenuItem>
)

const Options = ({
  textOption, setTextOption,
  tDotOption, setTDotOption,
  fontOption, setFontOption,
}) => {
  const classes = useStyles()

  const textMenu = textChoices.map(makeMenuItem)
  const tDotMenu = tDotChoices.map(makeMenuItem)
  const fontMenu = fontChoices.map(makeMenuItem)

  return (
    <>
      <Box className={classes.header}>
        <Typography
          variant="h5"
        >
          Options
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-around" py={1}>
        <TextField
          className={classes.textOption}
          id="select-text-option"
          select
          label="Text"
          value={textOption}
          onChange={event => setTextOption(event.target.value)}
          helperText=""
        >
          {textMenu}
        </TextField>
        <TextField
          className={classes.tDotOption}
          id="select-tdot-option"
          select
          label="T dot"
          value={tDotOption}
          onChange={event => setTDotOption(event.target.value)}
          helperText=""
        >
          {tDotMenu}
        </TextField>
        <TextField
          className={classes.fontOption}
          id="select-font-option"
          select
          label="Font"
          value={fontOption}
          onChange={event => setFontOption(event.target.value)}
          helperText=""
        >
          {fontMenu}
        </TextField>
      </Box>
    </>
  )
}

export default Options
