import React,{useEffect} from 'react';
import LTspiceToSVG from '../LTspiceToSVG'

const TryLtspice2svg = () => {
  useEffect(()=>{
    document.title='Icewire - Try ltspice2svg'
  },[])
  return (
    <div className="main">
      <LTspiceToSVG />
    </div>
  )
};

export default TryLtspice2svg;
