import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import axios from 'axios'

import Header from './Header'
import Gutter from './Gutter'
import Upload from './Upload'
import Options from './Options'
import Convert from './Convert'
import SvgViewer from './SvgViewer'
import { textChoices, tDotChoices, fontChoices } from './types/options'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '88vh',
    padding: 0,
    border: '1px solid black'
  },
  header: {
    background: '#c4e8ff'
  },
  menu: {
    flex: '0 0 500px'
  },
  convert: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  viewer: {
    width: '100%',
    height: '100%'
  }
}))

const LTspiceToSVG = () => {
  const classes = useStyles()

  const [textOption, setTextOption] = useState(textChoices[0].value)
  const [tDotOption, setTDotOption] = useState(tDotChoices[0].value)
  const [fontOption, setFontOption] = useState(fontChoices[0].value)
  const [uploadFile, setUploadFile] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [svgData, setSvgData] = useState(null)

  const convert = async () => {
    setIsLoading(true)

    const formData = new FormData();
    formData.append('myFile', uploadFile)
    formData.append('text', textOption)
    formData.append('tdots', tDotOption)
    formData.append('fontType', fontOption)
    
    try {
      const response = await axios.post('/uploadasc', formData, {
        headers: {
          'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8',
          'Content-Type': 'multipart/form-data'
        }
      })

      if (!response || !response.data || !response.data.startsWith('<?xml')) return;

      setSvgData(response.data)
      setIsLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Container maxWidth={false} className={classes.container}>

        <Box className={classes.header}>
          <Header />
        </Box>

        <Box display="flex" height="100%">

          <Box className={classes.menu}>
            <Box py={1} display="flex" justifyContent="center" alignItems="center">
              <Upload {...{uploadFile, setUploadFile}} />
            </Box>
            <Box borderBottom={1}>
              <Options {...{
                textOption, setTextOption,
                tDotOption, setTDotOption,
                fontOption, setFontOption,
              }} />
            </Box>
            <Box mt={10} display="flex" justifyContent="center" alignItems="center">
              <Convert {...{convert}} />
            </Box>
          </Box>

          <Gutter />

          <Box className={classes.viewer}>
            <SvgViewer
              {...{isLoading, svgData}}
              documentName={uploadFile && uploadFile.name.split('.')[0]}
            />
          </Box>
          
        </Box>
        
        
      </Container>
    </>
  )
}

export default LTspiceToSVG
