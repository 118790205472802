const colors = {
  base: '#509bcb',
  fg1: '#fff',
  fg2: '#424242',
  bg1: '#4e9bca33',
  bg2: '#509bcb',
  bg2Hover: '#4DBBFF',
  bg3: '#fff',
  bg4: '#4A57D9',
  bg4Hover: '#707CFF',
  errorLit: '#FF7E4A',
  errorLitHover: '#BF5E37',
  errorSelected: '#fff',
  errorSelectedHover: '#ddd'
}


export default colors
