import { makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles((theme) => ({
  noMargin: {
    marginBottom: 0
  }
}))

const RandomInput = ({ randomFlag, setRandomFlag }) => {
  const classes = useStyles()
  
  const handleChange = event => {
    setRandomFlag(event.target.checked)
  }
  
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={randomFlag}
          onChange={handleChange}
          name="random-flag"
          color="primary"
        />
      }
      label="Randomize variables"
      classes={{
        root: classes.noMargin
      }}
    />
  )
}

export default RandomInput
