import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import Tooltip from '@material-ui/core/Tooltip'

import { DownloadIcon, UploadIcon } from '../../../icons'
import theme from '../theme'
import { getEditorContent, setEditorContent } from '../lib'

const useStyles = makeStyles(() => ({
  left: {
    position: 'absolute'
  },
  right: {
    position: 'absolute',
    right: 0,
    display: 'flex'
  },
  prbNameInput: {
    marginTop: '10px',
    marginLeft: '30px'
  },
  prbNameInputField: {
    color: theme.fg1
  },
  prbNameInputUnderline: {
    '&:before': {
      borderBottom: '1px solid rgba(255, 255, 255, 0.42)'
    },
    '&:after': {
      borderBottom: '2px solid #fff'
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid rgba(255, 255, 255, 0.87)'
    }
  },
  errorButton: {
    marginLeft: '20px',
    boxShadow: 'rgb(0 0 0 / 20%) -2px -2px 4px -1px, rgb(0 0 0 / 14%) -2px -4px 5px 0px, rgb(0 0 0 / 0%) -2px -1px 10px 0px',
    borderRadius: '0px',
    borderTopLeftRadius: '16px',
    border: 0,
    backgroundColor: theme.bg2,
    color: theme.fg1,
    height: '37px',
    marginTop: '6px',
    '&:hover': {
      backgroundColor: theme.bg2Hover
    },
    '&:focus': {
      outline: 0
    }
  },
  errorRed: {
    backgroundColor: theme.errorLit,
    color: theme.fg1,
    '&:hover': {
      backgroundColor: theme.errorLitHover
    }
  },
  errorButtonSelected: {
    backgroundColor: `${theme.bg3} !important`,
    color: `${theme.fg4} !important`,
    border: 0,
    '&:hover': {
      backgroundColor: `${theme.errorSelectedHover} !important`
    }
  },
  compileButton: {
    border: `2px solid ${theme.bg1}`,
    zIndex: 1,
    backgroundColor: theme.bg4,
    color: theme.fg1,
    borderRadius: '30px',
    marginBottom: '3px',
    width: '200px',
    boxShadow: 'rgb(0 0 0 / 50%) 0px 2px 4px -1px, rgb(0 0 0 / 40%) 0px 2px 5px 0px, rgb(0 0 0 / 32%) 0px 1px 10px 0px',
    '&:hover': {
      backgroundColor: theme.bg4Hover
    }
  },
  compileButtonStartIcon: {
    marginRight: '24px'
  },
  compileButtonEndIcon: {
    marginLeft: '24px'
  },
  icon: {
    color: theme.fg1,
    '&:hover': {
      backgroundColor: theme.bg2Hover
    }
  },
  tooltipTop: {
    margin: '8px 0'
  }
}))

const Header = ({
  editorRef,
  documentName,
  setDocumentName,
  pdfData,
  showErrorLog,
  setShowErrorLog,
  errorProblemToTex,
  errorLatex,
  compileButtonText,
  compile
}) => {
  const classes = useStyles()
  
  const [inputVal, setInputVal] = useState('')

  const pdfObjectSrc = `data:application/pdf;base64,${pdfData}`

  const handleChangeFile = async event => {
    const file = event.target.files.item(0)
    const fileName = file.name.slice(0, file.name.lastIndexOf('.'))
    const text = await file.text()
    
    setEditorContent(editorRef, text)
    setDocumentName(fileName)
    
    // Reset the value of the input element so the change event will get triggered again if the previous file is selected again
    setInputVal('')
  }

  const handleDownload = event => {
    const tmpLink = document.createElement('a')
    const file = new Blob([getEditorContent(editorRef)], {type: 'text/plain'})
    tmpLink.href = URL.createObjectURL(file)
    tmpLink.download = `${documentName}.prb`
    document.body.appendChild(tmpLink)
    tmpLink.click()
    document.body.removeChild(tmpLink)
  }

  const handleChangePrbName = event => {
    setDocumentName(event.target.value)
  }

  const UploadButton = () => (
    <>
      <input
        accept=".prb"
        id="upload-prb"
        type="file"
        onChange={handleChangeFile}
        value={inputVal}
      />
      <label htmlFor="upload-prb">
        <Tooltip
          classes={{
            tooltipPlacementTop: classes.tooltipTop
          }}
          title="Upload PRB"
          placement="top"
          arrow
        >
          <IconButton className={classes.icon} aria-label="upload" component="span">
            <UploadIcon />
          </IconButton>
        </Tooltip>
      </label>
    </>
  )

  const DownloadButton = () => (
    <Tooltip
      classes={{
        tooltipPlacementTop: classes.tooltipTop
      }}
      title="Download PRB"
      placement="top"
      arrow
    >
      <IconButton className={classes.icon} onClick={handleDownload} aria-label="download" component="span">
        <DownloadIcon />
      </IconButton>
    </Tooltip>
  )

  return (
    <>
      <Box className={classes.left}>
        <UploadButton/>
        <DownloadButton />
        <TextField
          className={classes.prbNameInput}
          InputProps={{
            classes: {
              root: classes.prbNameInputField,
              underline: classes.prbNameInputUnderline
            }
          }}
          size="small"
          value={documentName}
          onChange={handleChangePrbName}
          variant="standard"
        />
      </Box>
      <Box className={classes.right}>
        {pdfData &&
         <Box ml={1} display="inline">
           <Tooltip
             classes={{
               tooltipPlacementTop: classes.tooltipTop
             }}
             title="Download PDF"
             placement="top"
             arrow
           >
             <IconButton
               className={classes.icon}
               aria-label="download PDF"
               component={Link}
               href={pdfObjectSrc}
               download={`${documentName}.pdf`}
             >
               <DownloadIcon />
             </IconButton>
           </Tooltip>
         </Box>
        }
        <ToggleButton
          className={classes.errorButton}
          classes={{
            root: (errorProblemToTex || errorLatex) && classes.errorRed,
            selected: classes.errorButtonSelected
          }}
          value="errorLog"
          size="small"
          selected={showErrorLog}
          onChange={() => setShowErrorLog(!showErrorLog)}
        >
          Error log
        </ToggleButton>
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          className={classes.compileButton}
          onClick={compile}
          variant="contained"
          startIcon={<ArrowUpwardIcon />}
          endIcon={<ArrowDownwardIcon />}
          classes={{
            startIcon: classes.compileButtonStartIcon,
            endIcon: classes.compileButtonEndIcon
          }}
        >
          {compileButtonText}
        </Button>
      </Box>
    </>
  )
}

export default Header
